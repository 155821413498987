import React from "react"
import { Link } from "gatsby"
import "./button.scss"

function Slide({ link, theme, children }) {
    return (
        <Link to={link} className={`xd-button ${theme ? `xd-button--${theme}` : `xd-button--default`}`}>{ children }</Link>
    );
  }

export default Slide
