import React from "react"

function Example({ name, children, contained }) {
    return (
        <div className="py-12">
            <div className="container">
                <div className="text-accent mb-4">{ name }</div>
            </div>
            {contained && <div className="container">{children}</div>}
            {!contained && children}
        </div>
    );
  }

export default Example
