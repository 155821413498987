import React from "react"
import "./button-container.scss"

function ButtonContainer(props) {
    return (
      <div className={`xd-button-container ${props.stacked ? "xd-button-container--stacked" : ""}`}>
        {props.children}
      </div>
    );
  }

export default ButtonContainer
