import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"

import ButtonContainer from "../components/button-container/button-container"
import Button from "../components/button/button"

import Example from "../components/example/example"

import Slide from "../components/slide/slide"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import paisley from "../images/paisley.svg"

const settings = {
  autoplaySpeed: 7000,
  dots: true,
  infinite: true,
  autoplay: true,
  adaptiveHeight: true
}

const DesignSystem = () => (
  <Layout>
    <Meta title="Design System"/>

      <Example name="Headings" contained>
        <div className="space-y-4">
            <h1 className="xd-h1">Heading H1</h1>
            <h2 className="xd-h2">Heading H2</h2>
            <h3 className="xd-h3">Heading H3</h3>
            <p>Body font - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu est tortor. Maecenas rhoncus mauris nisi, et porttitor sapien ultrices eu. Etiam malesuada laoreet dignissim.</p>
            <p class="text-meta">Meta - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu est tortor. Maecenas rhoncus mauris nisi, et porttitor sapien ultrices eu. Etiam malesuada laoreet dignissim.</p>
        </div>
      </Example>

      <Example name="Carousel">
        <Slider {...settings} className="overflow-hidden">
           <Slide background="https://images.unsplash.com/photo-1546484396-fb3fc6f95f98?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" content={<img src={paisley} alt=""/>} description={<img src={paisley} alt=""/>}></Slide>
           <Slide background="https://images.unsplash.com/photo-1531824475211-72594993ce2a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" content={<img src={paisley} alt=""/>} description={<img src={paisley} alt=""/>}></Slide>
        </Slider>
      </Example>

      <Example name="Button Container" contained>
        <ButtonContainer>
            <Button theme="default">Default</Button>
            <Button theme="primary">Primary</Button>
            <Button theme="secondary">Secondary</Button>
        </ButtonContainer>
      </Example>

      <Example name="Button Container (stacked)" contained>
        <ButtonContainer stacked>
            <Button theme="default">Default</Button>
            <Button theme="primary">Primary</Button>
            <Button theme="secondary">Secondary</Button>
        </ButtonContainer>
      </Example>

  </Layout>
)

export default DesignSystem
